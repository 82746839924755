import { graphql } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { useEffect } from "react"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import Layout from "../components/layout"
import Seo from "../components/seo"
import zoomIcon from "../images/zoom.svg"
import "../styles/referanslar.css"

const References = props => {
  const { language } = useI18next()

  useEffect(() => {
    document.body.className = "reference"
    return () => {
      document.body.className = ""
    }
  })
  const options = {
    buttons: {
      showFullscreenButton: false,
      showDownloadButton: false,
      showThumbnailsButton: false,
      showAutoplayButton: false,
    },
  }
  const references = props.data?.references?.nodes || []

  return (
    <Layout>
      <Seo title="Referanslar" />
      <div className="mt-60 w-9/12 mx-auto md:w-11/12 md:mt-24">
        <div className="w-full flex justify-between flex-wrap items-stretch">
          {references?.map((reference, index) => (
            <div className="w-[31%] bg-white rounded-md shadow-md mb-12 flex items-stretch xl:w-[47%] md:w-full flex-col md:mb-4 overflow-hidden">
              <div className="w-full relative flex items-center">
                <div className="flex items-center justify-center bg-black/20 h-full">
                  <img
                    src={reference.frontmatter.reference_image}
                    className="w-8/12"
                    alt="referans logo"
                  />
                </div>
                <SimpleReactLightbox>
                  <SRLWrapper options={options}>
                    {reference.frontmatter.referans_resimleri.map(
                      (image, index) => (
                        <img
                          key={image.resim}
                          src={image.resim}
                          alt=""
                          className="w-full cursor-pointer"
                          style={{
                            display: index === 0 ? "block" : "none",
                          }}
                        />
                      )
                    )}
                  </SRLWrapper>
                </SimpleReactLightbox>
                <img
                  src={zoomIcon}
                  alt="zoomIcon"
                  className="absolute right-1 bottom-1"
                />
              </div>
              <div className="p-3 w-full">
                <h5 className="font-first text-default-blue text-xl font-semibold mb-1 text-center">
                  {reference.frontmatter.title}
                </h5>
                <h6 className="font-first text-default-dark text-[16px] font-normal mb-1 leading-5 text-center">
                  {language === "en"
                    ? reference.frontmatter.product_title_en
                    : reference.frontmatter.product_title}
                </h6>
                <p className="font-first text-default-opacity opacity-95 font-light text-[14px] mb-0 text-center">
                  {language === "en"
                    ? reference.frontmatter.content_en
                    : reference.frontmatter.content}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default References

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    references: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "reference" } } }
    ) {
      nodes {
        frontmatter {
          title
          product_title
          product_title_en
          reference_image
          referans_resimleri {
            resim
          }
          content
          content_en
        }
      }
    }
  }
`
